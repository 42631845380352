import React, { useState, useEffect } from 'react';

import {graphql} from 'gatsby';
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"


import ReactPlayer from 'react-player'

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled, { keyframes } from "styled-components"

import {
  mainBackgroundColour, 
  creditsPanelBackgroundColour, 
  mainFontColour, 
  creditsHeadingColour, 
  creditsNameColour, 
  creditsGeneralFontColour, 
  expandColour,
  mainBackgroundColourImage,
  mainBackgroundAttachment,
  mainBackgroundSize,
  creditsPanelBoxShadow,
  creditsPanelExpanded,
  creditsPanelBackdropFilter,
} from './../components/theme';


export const Container = styled.div`
  min-height: calc(100vh - (36px + 50px));
  min-height: calc(100vh - 50px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
  background-color: ${mainBackgroundColour};
  background-image: ${mainBackgroundColourImage};
  background-attachment: ${mainBackgroundAttachment};
  background-size: ${mainBackgroundSize};

    
  @media screen and (max-width: 1025px) {
    padding-top: 0;
    min-height: auto;
  }
`

export const Article = styled.div`
  // display: grid;
  // grid-template-columns: repeat(12, [col-start] 1fr);
  // grid-column-gap: 32px;
  max-width: 1280px;
  min-height: inherit;
  position: relative;
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -250px;

  
  @media screen and (max-width: 1025px) {
    width: 100%;
    margin-left: 0;
    align-items: flex-start;
    margin-top: 10px;
  }


`

export const VideoWrapper = styled.div`
  // grid-column-start: 2;
  // grid-column-end: 10;
  position: relative;
  padding-bottom: 56.25%;
  height: 0px;
  // overflow: hidden;
  background: black;
  z-index: 10;
  width: 100%;
  
  .fp-player {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
`

export const InnerWrapper = styled.div`
  position: relative;
  width: 100%;

  @media screen and (max-width: 1025px) {
    display: flex;
    flex-direction: column;
  }
  /* &:nth-child(1) { order: 3; }
  &:nth-child(2) { order: 1; }
  &:nth-child(3) { order: 2; } */
`

export const H1 = styled.h1`
  font-family: 'futura-pt', sans-serif;
  font-weight: 700;
  color: ${mainFontColour};


  @media screen and (max-width: 1025px) {
      font-size: 25px;
      margin-bottom: 0;
      margin-left: 5px;
  }
`

const keyFramesAnimation = keyframes`
  0% {
    z-index: 1;
  }
  3% {
    z-index: 1;
  }
  100% {
    z-index: 15;
    box-shadow: 1px 2px 5px rgba(0,0,0,.3);
  }
`
export const FullCast = styled.ul`
  opacity: 1;
  margin-top: 30px;
  text-align: left;
  width: 250px;
  transition: all 1s ease;
  
  & li {
    list-style: none;
    width: 250px;
  }

  @media screen and (max-width: 1025px) {
    width: auto;
    margin-top: 0;
  }
`

export const Credits = styled.div`
  background: ${creditsPanelBackgroundColour};
  box-shadow: ${creditsPanelBoxShadow};
  backdrop-filter: ${creditsPanelBackdropFilter};
  position: absolute;
  width: 300px;
  height: 400px;
  top: -40px;
  right: -250px;
  z-index: 1;
  overflow: hidden;
  transition: all 3s cubic-bezier(0.71, 0.94, 0.73, 1.99);
  transition: all 3s cubic-bezier(0.67,-0.51, 0.32, 1.28);
  transition: all 3s cubic-bezier(0.41, 0.74, 0.61, 1.38);
  transition: z-index 0s 2s, width 3s cubic-bezier(0.41, 0.74, 0.61, 1.38), box-shadow 3s ease;
  transition: z-index 0s 1s, width 1.5s cubic-bezier(0.41, 0.74, 0.61, 1.38), box-shadow 1.5s ease, background-color 1.5s ease;
  animation: 3s ${keyFramesAnimation} ease;
  &.expanded {
    background: ${creditsPanelExpanded};
    width: 100%;
    z-index: 15;
    box-shadow: 1px 2px 5px rgba(0,0,0,.3);
    transition: all 3s cubic-bezier(0.41, 0.74, 0.61, 1.38);
    transition: all 3s cubic-bezier(0.67,-0.51, 0.32, 1.28);
    transition: z-index 0s 0.5s, width 3s cubic-bezier(0.67,-0.51, 0.32, 1.28), box-shadow 3s ease;
    transition: z-index 0s 0.25s, width 1.5s cubic-bezier(0.67,-0.51, 0.32, 1.28), box-shadow 1.5s ease, background-color 1.5s ease;
    animation: 3s ${keyFramesAnimation} ease;
    animation-direction: reverse;
    color: white;
    & ${FullCast} {
      opacity: 1;
      // transition: all 2s ease;
    }
  }

    
  @media screen and (max-width: 1025px) {
    position: relative;
    height: auto;
    width: auto;
    top: auto;
    right: auto;
    margin: 2px;
  }
`

export const CreditsInner = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  text-align: right;
  position: relative;
  display: flex;

  @media screen and (max-width: 1025px) {
    flex-direction: column;
  }
`
export const CreditsTitle = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-weight: 300;
  font-style: italic;
  color: ${creditsGeneralFontColour};
  padding: 5px;
  position: absolute;
  top: 0;
  left: 0;
`

export const CreditsHeading = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-weight: 700;
  color: ${creditsHeadingColour};
`

export const CreditsName = styled.div`
  font-family: 'futura-pt', sans-serif;
  font-weight: 400;
  color: ${creditsNameColour};
`

export const MainCredits = styled.ul`
  min-width: 250px;
  width: 250px;
  padding-left: 40px;
  text-align: left;
  margin-top: 30px;
  & li {
    list-style: none;
    width: 250px
  }

  @media screen and (max-width: 1025px) {
    margin-bottom: 0;
  }
`



export const Expand = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  position: absolute;
  font-family: 'futura-pt', sans-serif;
  font-weight: 600;
  font-style: italic;
  color: ${expandColour};

  @media screen and (max-width: 1025px) {
    display: none;
  }
`


export const query = graphql`
query ($slug: String!) {
      markdownRemark ( fields: { slug: { eq: $slug } }) {
        frontmatter {
          title
          vimeo_link
          director
          producer
          dp
          production_company
          production_designer
          vfx
          editor
          sound
          screenplay
          client
          language
        }
      }
}
`
let url = 'https://vimeo.com/436417812'

const VideoPageTemplate = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    
    const { 
      title, 
      director, 
      vimeo_link, 
      producer, 
      dp, 
      production_company, 
      client, 
      production_designer, 
      vfx, 
      editor, 
      sound,
      screenplay 
    } = props.data.markdownRemark.frontmatter
    
    const {t} = useI18next();
    console.log(props);
    
    const context = React.useContext(I18nextContext);
    const language = context.language;
    const defaultLanguage = context.defaultLanguage;

      return (
        <Layout>
          <SEO title={title} />
          <Container>
            <Article>
              <InnerWrapper>
              <VideoWrapper>
                  <ReactPlayer 
                        className='fp-player'
                        url={vimeo_link}
                        playing={false}
                        volume={null}
                        // light={true}
                        width="100%" 
                        height="100%" 
                        controls={true}
                        config={{
                            vimeo: {
                              playerOptions: {
                                  loop: true,
                              }
                            },
                        }}
                    />
                    
                </VideoWrapper>
                <H1>{title}</H1>
                <Credits className={ isOpen ? 'expanded' : null }>
                  <CreditsTitle>Credits</CreditsTitle>
                  <CreditsInner>
                    <MainCredits>
                      { director && director.length &&
                      <li>
                        <CreditsHeading>{t('TEMPLATE.DIRECTOR')}</CreditsHeading>
                        <CreditsName>{director}</CreditsName>
                      </li>
                      }
                      { producer && producer.length > 0 &&
                      <li>
                        <CreditsHeading>Producer</CreditsHeading>
                        {producer.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                      </li>
                      }
                      { dp && dp.length > 0 &&
                      <li>
                        <CreditsHeading>{t('TEMPLATE.DP')}</CreditsHeading>
                        {dp.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                      </li>
                      }
                      { production_company && production_company.length > 0 &&
                      <li>
                        <CreditsHeading>{t('TEMPLATE.PRODUCTION_COMPANY')}</CreditsHeading>
                        {production_company.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                      </li>
                      }
                      { client && client.length > 0 &&
                      <li>
                        <CreditsHeading>{t('TEMPLATE.CLIENT')}</CreditsHeading>
                        {client.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                      </li>
                      }
                    </MainCredits>
                    {
                      ((production_designer && production_designer.length > 0) || 
                      (vfx && vfx.length > 0) ||
                      (screenplay && screenplay.length > 0) ||
                      (editor && editor.length > 0) ||
                      (sound && sound.length > 0)) && 
                      <React.Fragment>
                        <FullCast>
                        { screenplay && screenplay.length > 0 &&
                          <li>
                            <CreditsHeading>{t('TEMPLATE.SCREENPLAY')}</CreditsHeading>
                            {screenplay.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                          </li>
                        }
                        { production_designer && production_designer.length > 0 &&
                          <li>
                            <CreditsHeading>{t('TEMPLATE.PRODUCTION_DESIGNER')}</CreditsHeading>
                            {production_designer.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                          </li>
                        }
                        { vfx && vfx.length > 0 &&
                          <li>
                            <CreditsHeading>VFX</CreditsHeading>
                            {vfx.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                          </li>
                        }
                        { editor && editor.length > 0 &&
                          <li>
                            <CreditsHeading>{t('TEMPLATE.EDITOR')}</CreditsHeading>
                            {editor.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                          </li>
                          }
                        { sound && sound.length > 0 &&
                          <li>
                            <CreditsHeading>{t('TEMPLATE.SOUND')}</CreditsHeading>
                            {sound.map((el, index)=>{return <CreditsName key={index}>{el}</CreditsName>})}
                          </li>
                        }
                        </FullCast>
                        <Expand onClick={()=>{setIsOpen(!isOpen)}}>{isOpen ? 'Hide full credits': '« Expand credits'}</Expand>
                      </React.Fragment>
                    }
                        
                  </CreditsInner>
                </Credits>
                
              </InnerWrapper>
            </Article>
          </Container>
        </Layout>
      )
}

export default VideoPageTemplate;